import React, { Component } from 'react';
//import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import Layout from "../../../components/layout"
import { withStyles } from '@material-ui/core/styles';
import { 
  Paper,
  Card,
  Grid,
  TextField,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
 } from '@material-ui/core';
 import { isNullOrUndefined } from 'util';
 import * as XLSX from 'xlsx'
 import Img from '../../../components/img'
 import moment from 'moment'
 import MUIDataTable from "mui-datatables";
import { navigate } from 'gatsby-link';
import SendData from  '../../../api/rh/send'
import ky from 'ky'

const styles = theme => ({
    paper: {
        width: "100%",
        maxWidth: "100%",
      },
    
      Grid: {
        width: '100%',
        height: "100%",
      },
    
      textField: {
        marginLeft: "20px"
    
      },
      textFieldM: {
        marginLeft: "20px",
        width: "70%"
      },
    
      btn: {
        marginRight: "10px"
      },
      CompName: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
      },
      input: {
        display: 'none',
      },
      margin: {
        marginLeft: "18.5px",
      },
      DivRadio: {
        marginTop: "12px",
      },
      TextFieldCol: {
        width: "90%"
      },
      DivBtn: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: "5%"
      },
      listErrors: {
        width: '100%',
        //maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
        '& ul': {
          backgroundColor: 'inherit',
          padding: 0,
        },
      },
      textFieldComp: {
        width: "80%",
        marginLeft: "10%",
        marginRight: "10%",
        textAlign: 'center',   
      },
    
      colap: {
        overflow: "auto",
        maxHeight: "100%",
        maxWidth: "100%"
      },
      btnSubmit: {
        display: "flex",
        justifyContent: "end",
        width: "100%",
        marginTop: "3%"
    
      },
      TableDiv: {
        maxHeight: "50vh",
        maxWidth: "99%",
        marginTop: "3%",
        overflow: "auto",
        //alignSelf: 'center'
      },
      
});

class CensusRH extends Component {
    constructor(props) {
        super(props);
        this.state = {
          messageErr: [], // [["Sin errores"]],
          data: [], /* Array of Arrays e.g. [["a","b"],[1,2]] */
          cols: [],  /* Array of column objects e.g. { name: "C", K: 2 } */
          indexFile: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '', 0],//[1,2,-1,-1,3,4,5,6,7,8,9,10,11,12,13,1,2],  // Array of column index
          selected: "Standard",
          showIndexColum: false,
          fileName: "No se ha seleccionado",
          fileArray: [],
          nameColums: "1",
          apellidos1: false,
          apellidos2: false,
          final: [],
          companyName: '',
          companyUuid: '',
          token: '',
          urlDoc: '',
    
        }};
        async componentDidMount(){
          console.log(this.props.location.state)
          if(this.props.location.state === null || !this.props.location.state.token ){
            navigate('/padron/')
          }else{
            this.setState({
              companyName: this.props.location.state.companyName,
              companyUuid: this.props.location.state.companyUuid,
              token: this.props.location.state.token,
            })
          }
        }

        async handleFile(file/*:File*/) {
          /* Boilerplate to set up FileReader */
          return new Promise(function (resolve, reject) {
            const make_cols = refstr => {
              let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
              for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i }
              return o;
            };
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = (e) => {
              /* Parse data */
              const bstr = e.target.result;
              const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', cellDates: true });
              /* Get first worksheet */
              const wsname = wb.SheetNames[0];
              //console.log(wb)
              const ws = wb.Sheets[wsname];
              /* Convert array of arrays */
              const data = XLSX.utils.sheet_to_json(ws, { header: 1, dateNF: "YYYY-MM-DD" });
              /* Update state */
              resolve(({ data: data.filter(function (el) { return el.length }), cols: make_cols(ws['!ref']) }));
            };
            reader.onerror = reject;
            if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
          });
        };
        converFile = async (file)=>{
          //var file = e.target.files[0]
          const raw = await Img.getBinaryStringFile(file)
          var file64 = btoa(raw)
          //console.log(file64)
          this.setState({
            fileName: file.name,
            fileArray: file64
          })
                
        }
        concatName(name, lastNameF, lastNameM) {
          if (!isNullOrUndefined(lastNameF)) {
            if (!isNullOrUndefined(lastNameM)) {
              return (name + " " + lastNameF + " " + lastNameM)
            } else {
              return (name + " " + lastNameF)
            }
          }
          else {
            return name
          }
        }

        convertDate(d) {
          function pad(s) { return (s < 10) ? '0' + s : s; }
          //var d = new Date(inputFormat)
          return (d.getFullYear() + "-" + pad(d.getMonth() + 1) + "-" + pad(d.getDate()))
        }
        checkDate(date) {
          if (!isNullOrUndefined(date)&& typeof date !== 'number') {
            const newDate = date.replace(/ene/i, 'Jun').replace(/abr/i, 'Apr').replace(/ago/i, 'Aug').replace(/dic/i, 'Dec').replace(/-/g, "/")
            const converted = new Date(newDate)
            if (converted instanceof Date && !isNaN(converted)) {
              return this.convertDate(converted)
            } else {
      
              //moment.locale('es', localization)
              let mymoment = moment(newDate).format('YYYY-MM-DD')
              // console.log(newDate)
              if (mymoment !== 'Invalid date' && mymoment!=='Fecha inválida') {
                return mymoment
              }
              else {
                let mymoment = moment(newDate, "DD/MM/YYYY").format('YYYY-MM-DD')
                //console.log(newDate.replace(/\//g, ""))
                if (mymoment !== 'Invalid date' && mymoment!=='Fecha inválida') {
                  return mymoment
                } else return null
              }
      
            }
          } else {
            return null
          }
        }

        getGender(workersInfo) {
          const sex = ["hombre-mujer", "h-m", "masculino-femenino", "m-f", "male-female", "man-woman", "m-w"]
          let gender1 = ''
          let gender2 = ''
          let message = ""
          for (let i = 0; i < workersInfo.length; i++) {
            if (gender1 === "" && workersInfo[i].sex !== "") {
              gender1 = workersInfo[i].sex
            } else if (gender1 !== workersInfo[i].sex && workersInfo[i].sex !== "") {
              gender2 = workersInfo[i].sex
              if (sex.includes((gender1 + "-" + gender2).toLocaleLowerCase())) {
                for (let i = 0; i < workersInfo.length; i++) {
                  if (gender1.toLocaleLowerCase() === workersInfo[i].sex.toLocaleLowerCase()) {
                    workersInfo[i].sex = "HOMBRE"
                  } else if (gender2.toLocaleLowerCase() === workersInfo[i].sex.toLocaleLowerCase()) {
                    workersInfo[i].sex = "MUJER"
                  }else {
                    workersInfo[i].sex = ""
                  }
      
                }
              } else if (sex.includes((gender2 + "-" + gender1).toLocaleLowerCase())) {
                for (let i = 0; i < workersInfo.length; i++) {
                  if (gender2.toLocaleLowerCase() === workersInfo[i].sex.toLocaleLowerCase()) {
                    workersInfo[i].sex = "HOMBRE"
                  } else if (gender1.toLocaleLowerCase() === workersInfo[i].sex.toLocaleLowerCase()) {
                    workersInfo[i].sex = "MUJER"
                  }else {
                    workersInfo[i].sex = ""
                  }
      
                }
              } else {
                for (let i = 0; i < workersInfo.length; i++) {
                  workersInfo[i].sex = ""
                }
                message = "Advertencia: Campo \"Sexo\" no es valido en el documento"
              }
              break
            }
      
          }
          if (gender1 !== '' && gender2 === '' && !(gender1 === 'HOMBRE' || gender1 === 'MUJER')) {
            if(gender1.toLocaleLowerCase()==='masculino'){
              for (let i = 0; i < workersInfo.length; i++) {
                workersInfo[i].sex = 'HOMBRE'
              }
            }else if (gender1.toLocaleLowerCase()==='femenino'){
              for (let i = 0; i < workersInfo.length; i++) {
                workersInfo[i].sex = 'MUJER'
              }
            }else{
            for (let i = 0; i < workersInfo.length; i++) {
              workersInfo[i].sex = ""
            }}
            message = "Advertencia: Campo \"Sexo\" solo contiene un genero, por favor verifique el género y en caso de ser incorrecto cambie en el archivo de Excel por \"HOMBRE\" o \"MUJER\" dependiendo el caso."
          }
          var data = []
      
          workersInfo.map(User => {
            data.push([
              User.payroll,
              User.name,
              User.job,
              User.daySalary,
              User.entry,
              User.rfc,
              User.nss,
              User.dateOfBirth,
              User.city,
              User.localplace,
              User.address,
              User.sex,
              User.scholarship,
              User.affiliationDate
            ]);
            return data
          })
      
          this.setState({ final: data });
          this.setState({ showPreview: true });
          data = null;
      
          return { message: message, data: workersInfo }
        };

        getInfo(results, indexData) {
          //console.log(results.data)
          const data = results.data
          let row = []
          let inDataRow = false;
          //let indexData
          let messageErr = []
          let fatalErr = false;
          let workersInfo = []
      
          const pattern = new RegExp(/^[A-ZÑ]{3,4}(\d{6})((\D|\d){1,3})?$/)
          //let cells = row
          //console.log(row)
          //console.log(indexData)
          for (let i = indexData.startData; i < data.length; i++) {
            row = data[i]
            //console.log(row)
            if (!inDataRow && indexData.startData === 0 && indexData.headers === '') {
              if (row.includes("rfc") && row.includes("nombre")) { //&& row.includes("nss") && row.includes("salario_tab")) {
               
                indexData = {
                  "no_nomina": row.indexOf("no_nomina") === -1 ? (messageErr.push("Falta el campo de no_nomina"), -1) : row.indexOf("no_nomina"),
                  "nombre": row.indexOf("nombre"),//row.indexOf("nombre") === -1 ? (messageErr.push("Falta el campo de nombre"), -1) : row.indexOf("nombre"),
                  "apellidoP": row.indexOf("apellidos") === -1 ? (row.indexOf("apellidoP") === -1 ? -1 : row.indexOf("apellidoP")) : row.indexOf("apellidos"),//(messageErr.push("No hay campo de apellidos"), -1) : row.indexOf("apellidos"),
                  "apellidoM": row.indexOf("apellidoM") === -1 ? -1 : row.indexOf("apellidoM"), //(messageErr.push("No hay campo de apellidos"), -1) : row.indexOf("apellidos"),
                  "puesto": row.indexOf("puesto") === -1 ? (messageErr.push("Falta el campo de puesto"), -1) : row.indexOf("puesto"),
                  "salario_tab": row.indexOf("salario_tab") === -1 ? (messageErr.push("Falta el campo de salario_tab"), -1) : row.indexOf("salario_tab"),
                  "fecha_ingr": row.indexOf("fecha_ingr") === -1 ? (messageErr.push("Falta el campo de fecha_ingr"), -1) : row.indexOf("fecha_ingr"),
                  "rfc": row.indexOf("rfc") === -1 ? (messageErr.push("FATAL ERROR:No se encontro el campo \"rfc\""), fatalErr = true, -1) : row.indexOf("rfc"),
                  "nss": row.indexOf("nss") === -1 ? (messageErr.push("Falta el campo de nss"), -1) : row.indexOf("nss"),
                  "fecha_naci": row.indexOf("fecha_naci") === -1 ? (messageErr.push("Falta el campo de fecha_naci"), -1) : row.indexOf("fecha_naci"),
                  "municipio": row.indexOf("municipio") === -1 ? (messageErr.push("Falta el campo de municipio"), -1) : row.indexOf("municipio"),
                  "calle": row.indexOf("calle") === -1 ? (messageErr.push("Falta el campo de calle"), -1) : row.indexOf("calle"),
                  "colonia": row.indexOf("colonia") === -1 ? (messageErr.push("Falta el campo de colonia"), -1) : row.indexOf("colonia"),
                  "sexo": row.indexOf("sexo") === -1 ? (messageErr.push("Falta el campo de sexo"), -1) : row.indexOf("sexo"),
                  "escolaridad": row.indexOf("escolaridad") === -1 ? (messageErr.push("Falta el campo de escolaridad"), -1) : row.indexOf("escolaridad"),
                  "fecha_afilia": row.indexOf("fecha_afilia") === -1 ? (messageErr.push("Falta el campo de fecha_afilia"), -1) : row.indexOf("fecha_afilia"),
                }
                if (fatalErr) {
                  break;
                }
                inDataRow = true
                //console.log(indexData)
              }
            } else {
              //console.log(!(row[indexData.fecha_naci] instanceof Date))
             console.log(row)
              if (!isNullOrUndefined(row[indexData.rfc]) && pattern.test(row[indexData.rfc].toString().replace(/ /g, '').replace(/-/g, ''))) {
                let checkDate = ''
                workersInfo.push({
                  "payroll": (isNullOrUndefined(row[indexData.no_nomina])) ? (messageErr.push("Advertencia: Campo \"Numero de nomina\" es nulo. Fila " + (i + 1) + ", Columna: " + (indexData.no_nomina === -1 ? ("Inexistente") : (indexData.no_nomina + 1))), '') : (String(row[indexData.no_nomina])),
                  "name": (this.concatName(row[indexData.nombre], row[indexData.apellidoP], row[indexData.apellidoM])),//(isNullOrUndefined(row[indexData.nombre])) ? (messageErr.push("Advertencia: Campo \"Nombre\" es nulo. Fila " + (i + 1) + ", Columna: " + (indexData.nombre === -1 ? ("Inexistente") : (indexData.nombre+1))), '') : (this.concatName(row[indexData.nombre],row[indexData.apellidoP],row[indexData.apellidoM])),
                  //"apellidos": (isNullOrUndefined(row[indexData.apellidos])) ? (messageErr.push("Advertencia: Campo apellidos es nulo. Fila " + (i + 1) + ", Columna: " + (indexData.apellidos === -1 ? ("Inexistente") : (indexData.apellidos))), '') : (row[indexData.apellidos]),
                  "job": (isNullOrUndefined(row[indexData.puesto])) ? (messageErr.push("Advertencia: Campo \"Puesto\" es nulo. Fila " + (i + 1) + ", Columna: " + (indexData.puesto === -1 ? ("Inexistente") : (indexData.puesto + 1))), '') : (row[indexData.puesto]),
                  "daySalary": (isNullOrUndefined(row[indexData.salario_tab])) ? (messageErr.push("Advertencia: Campo \"Salario tabulado\" es nulo. Fila " + (i + 1) + ", Columna: " + (indexData.salario_tab === -1 ? ("Inexistente") : (indexData.salario_tab + 1))), '') : (String(row[indexData.salario_tab]).replace('$', '').replace('$ ')),
                  "entry": (isNullOrUndefined(row[indexData.fecha_ingr])) ? (messageErr.push("Advertencia: Campo \"Fecha de ingreso\" es nulo. Fila " + (i + 1) + ", Columna: " + (indexData.fecha_ingr === -1 ? ("Inexistente") : (indexData.fecha_ingr + 1))), null) : ((row[indexData.fecha_ingr] instanceof Date) ? (this.convertDate(row[indexData.fecha_ingr])) : ((checkDate = this.checkDate(row[indexData.fecha_ingr]), (checkDate ? checkDate : (messageErr.push("Advertencia: Formato de \"Fecha de ingreso\" no reconocido. Fila " + (i + 1) + ", Columna: " + (indexData.fecha_ingr + 1) + " Texto: " + row[indexData.fecha_ingr]), null))))),
                  "rfc": row[indexData.rfc].replace(/ /g, '').replace(/-/g, ''),
                  "nss": (isNullOrUndefined(row[indexData.nss])) ? (messageErr.push("Advertencia: Campo \"NSS\" es nulo. Fila " + (i + 1) + ", Columna: " + (indexData.nss === -1 ? ("Inexistente") : (indexData.nss + 1))), '') : (String(row[indexData.nss])),
                  "dateOfBirth": (isNullOrUndefined(row[indexData.fecha_naci])) ? (messageErr.push("Advertencia: Campo \"Fecha de nacimiento\" es nulo. Fila " + (i + 1) + ", Columna: " + (indexData.fecha_naci === -1 ? ("Inexistente") : (indexData.fecha_naci + 1))), null) : ((row[indexData.fecha_naci] instanceof Date) ? (this.convertDate(row[indexData.fecha_naci])) : ((checkDate = this.checkDate(row[indexData.fecha_naci]), (checkDate ? checkDate : (messageErr.push("Advertencia: Formato de \"Fecha de nacimiento\" no reconocido. Fila " + (i + 1) + ", Columna: " + (indexData.fecha_naci + 1) + " Texto: " + row[indexData.fecha_naci]), null))))),
                  "city": (isNullOrUndefined(row[indexData.municipio])) ? (messageErr.push("Advertencia: Campo \"Municipio\" es nulo. Fila " + (i + 1) + ", Columna: " + (indexData.municipio === -1 ? ("Inexistente") : (indexData.municipio + 1))), '') : (row[indexData.municipio]),
                  "address": (isNullOrUndefined(row[indexData.calle])) ? (messageErr.push("Advertencia: Campo \"Calle\" es nulo. Fila " + (i + 1) + ", Columna: " + (indexData.calle === -1 ? ("Inexistente") : (indexData.calle + 1))), '') : (row[indexData.calle]),
                  "localplace": (isNullOrUndefined(row[indexData.colonia])) ? (messageErr.push("Advertencia: Campo \"Colonia\" es nulo. Fila " + (i + 1) + ", Columna: " + (indexData.colonia === -1 ? ("Inexistente") : (indexData.colonia + 1))), '') : ((row[indexData.colonia].toString().replace(/col. /i,'').replace(/colonia /i, '').replace(/col /i)).normalize('NFD')
                  .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
                  .normalize()),
                  "sex": (isNullOrUndefined(row[indexData.sexo])) ? (messageErr.push("Advertencia: Campo \"Sexo\" es nulo. Fila " + (i + 1) + ", Columna: " + (indexData.sexo === -1 ? ("Inexistente") : (indexData.sexo + 1))), '') : (row[indexData.sexo]),
                  "scholarship": (isNullOrUndefined(row[indexData.escolaridad])) ? (messageErr.push("Advertencia: Campo \"Escolaridad\" es nulo. Fila " + (i + 1) + ", Columna: " + (indexData.escolaridad=== -1 ? ("Inexistente") : (indexData.escolaridad + 1))), '') : (row[indexData.escolaridad].replace(/ /g, '').replace(/-/g, '')),
                  //row[indexData.escolaridad].replace(/ /g, '').replace(/-/g, ''),
                  "affiliationDate": (isNullOrUndefined(row[indexData.fecha_afilia])) ? (messageErr.push("Advertencia: Campo \"Fecha de afiliación\" es nulo. Fila " + (i + 1) + ", Columna: " + (indexData.fecha_afilia === -1 ? ("Inexistente") : (indexData.fecha_afilia + 1))), null) : ((row[indexData.fecha_afilia] instanceof Date) ? (this.convertDate(row[indexData.fecha_afilia])) : ((checkDate = this.checkDate(row[indexData.fecha_afilia]), (checkDate ? checkDate : (messageErr.push("Advertencia: Formato de \"Fecha de afiliación\" no reconocido. Fila " + (i + 1) + ", Columna: " + (indexData.fecha_afilia + 1) + " Texto: " + row[indexData.fecha_afilia]), null))))),
                })
              } else {
                messageErr.push("Error: RFC es nulo o no concuerda con el formato. Fila " + (i + 1) + ", Columna: " + (indexData.rfc + 1) + ", Texto: " + row[indexData.rfc])
              }
      
            }
          }
          const result = this.getGender(workersInfo)
          if (result.message !== '')
            messageErr.push(result.message)
          if (workersInfo.length === 0 && messageErr.length === 0) {
            messageErr = ['Archivo no compatible o no contiene los datos minimos (Nombre y RFC).']
          }
          messageErr= messageErr.length===0?[["Sin incidencias."]]:messageErr

          console.log(workersInfo)
          this.setState({
            messageErr,
            data: workersInfo
          })
         
          delete (data, row, result, workersInfo)
          messageErr = []
        }

        readFile = async (event) => {
            const file = event.target.files[0];
            await this.saveFileInfo(event);
            if((file.size / 1024 / 1024)<=10){
            const readerDone = await this.handleFile(file)
            //console.log(readerDone.data)
            this.converFile(file)
            //Get info from server 
            const indexFile = this.state.indexFile;
            let indexData = {
                "no_nomina": indexFile[0],
                "nombre": indexFile[1],
                "apellidoP": indexFile[2],
                "apellidoM": indexFile[3],
                "puesto": indexFile[4],
                "salario_tab": indexFile[5],
                "fecha_ingr": indexFile[6],
                "rfc": indexFile[7],
                "nss": indexFile[8],
                "fecha_naci": indexFile[9],
                "municipio": indexFile[10],
                "calle": indexFile[11],
                "colonia": indexFile[12],
                "sexo": indexFile[13],
                "escolaridad": indexFile[14],
                "fecha_afilia": indexFile[15],
                "headers": indexFile[16],
                "startData": indexFile[17]
              }        
            this.getInfo(readerDone, indexData) 
            }else{
                alert('El tamaño de archivo es demasiado grande, por favor revise que solo tenga los datos necesarios.')
            }
        }

        SendPadron = async () => {
          if (/*!isNullOrUndefined(this.state.companyUuid)&&*/this.state.data !== [] && this.state.data.length !== 0) {
            //console.log(this.state.companyUuid)
            const create = await SendData(this.state.companyUuid, this.state.token,this.state.data, this.state.urlDoc )
            //ColCall.UpdateEmployees(this.state.data, this.state.companyUuid,{name: this.state.fileName, content:this.state.fileArray})
            //alert(create.status!=='error'?"Su padrón ha sido enviado correctamente, estará disponible para consulta en unos minutos en el apartado histórico de padrones": create.data.message)
            if(create.status==='error' && create.data.message === 'jwt expired'){
              alert('Su sesión ha expirado, se le enviará a la página de inicio de sesión.');
              navigate('/padron/')
            }else if( create.status !== 'error'){
              alert('El padrón ha sido enviado, su asesor tendrá acceso al mismo en unos minutos.');
              alert('Proceso terminado con éxito.')
              this.setState({
                showPreview: false,
                messageErr: [], // [["Sin errores"]],
                data: [], 
                cols: [],  
                indexFile: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '', 0],
                selected: "Standard",
                showIndexColum: false,
                fileName: "No se ha seleccionado",
                fileArray: [],
                nameColums: "1",
                apellidos1: false,
                apellidos2: false,
                final: [],
                urlDoc: null,
                
              })
            }else{
              alert(create.data.message);
            }
          }
          else {
            alert('Revise los datos que esta intentando enviar')
          }
        };
        saveFileInfo = async (e) => {
          try {
            const tokenA = this.state.token;
            const dataForm = new FormData();
            dataForm.append("file", e.target.files[0]);
            dataForm.append("name", e.target.files[0].name.replace(/[^0-9A-Za-z.]/gi, '_'));
            dataForm.append("location", "SITIMM_PADRONES_" + this.state.companyUuid);
      
            const res = await ky.post(
              "https://api.sitimm.org/api/imgs/create",
              {
                body: dataForm,
               /*  headers: {
                  "Content-Type": "multipart/form-data",
                  //jwt: `${tokenA}`,
                }, */
              }
            ).json();
            //console.log(res, res.status);
            if (res.status === "success") {
              this.setState({
                urlDoc: res.data.location,
              });
              //console.log("El Archivo fue cargado exitosamente");
            } else {
              alert("Error en la recepcion del archivo. Inténtelo de nuevo.");
            }
          } catch (e) {
            console.log(e);
            alert("Error en la carga. Inténtelo de nuevo.");
            this.setState({
              urlDoc: null,
            });
          }
        };

    render(){
        const { classes } = this.props;
        const {showPreview, final, fileName } = this.state;
        const columns = ["Nomina", "Nombre", "Puesto", "Salario", "Fecha Ingreso", "RFC", "Seguro Social", "Fecha nacimiento", "Municipio", "Colonia", "Calle", "Sexo", "Escolaridad", "Fecha de afiliación"];
    const options = {
      textLabels: {
        body: {
          noMatch: "Sin datos",
          toolTip: "Ordenar",
          columnHeaderTooltip: column => `Ordenar por ${column.label}`
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: 'textField',
      page: 0,
      selectableRows: "none",
      print: false
    };
        return (
            <Layout>
                <div className={classes.paper}>
                    <Paper>
                        <Card>
                            <Grid container className={classes.Grid} >
                                <Grid item xs={12} className={classes.Grid}
                                    container
                                    direction="column"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                >
                                    <div className={classes.CompName}>
                                        <TextField
                                            id="standard-read-only-input"
                                            label=""
                                            defaultValue=" "
                                            value={this.state.companyName}
                                            className={classes.textFieldComp}
                                            inputProps={{ style: { textAlign: 'center' }}}
                                            margin="normal"
                                            InputProps={{
                                            readOnly: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className={classes.DivBtn}>
                                        <p>{"𝗔𝗿𝗰𝗵𝗶𝘃𝗼: " + fileName}</p>
                                    </div>
                                    <div className={classes.DivBtn}>
                                        <input type="file" id="files" accept=".csv, .xlsx, .xls" onChange={this.readFile.bind(this)} required className={classes.input} multiple />
                                        <label htmlFor="files">
                                            <Button variant="contained" color="primary" component="span" className={classes.btn}>
                                                Subir archivo
                                            </Button>
                                        </label>
                                    </div>
                                    <div className={classes.TableDiv}>
                                      <Collapse in={showPreview} className={classes.colap}>
                                        <Paper className={classes.paperTable}>
                                          <Table>
                                            <TableHead>
                                              <TableRow>
                                                <TableCell>Errores/Advertencias</TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {this.state.messageErr.map(error => {
                                                return (
                                                  <TableRow key={`item-${error}`}>
                                                    <TableCell>{error}</TableCell>
                                                  </TableRow>
                                                );
                                              })}
                                            </TableBody>
                                          </Table>
                                        </Paper>
                                      </Collapse>
                                    </div>
                                    <div className={classes.TableDiv}>
                                      <Collapse in={showPreview} className={classes.colap}>
                                        <Paper className={classes.paperTable}>
                                          <MUIDataTable
                                            download={false}
                                            title={"Preview"}
                                            data={final}
                                            columns={columns}
                                            options={options}
                                          />
                                        </Paper>
                                      </Collapse>
                                    </div>
                                    <Collapse in={showPreview} className={classes.btnSubmit}>
                                      <Button variant="contained" color="primary" component="span" onClick={this.SendPadron} className={classes.btn}>
                                        Enviar padron
                                        </Button>
                                    </Collapse>
                                </Grid>
                            </Grid>
                        </Card>
                    </Paper>
                </div>
            </Layout>)
    }
};

CensusRH.propTypes = {
    classes: PropTypes.object.isRequired,
  };
export default withStyles(styles)(CensusRH)