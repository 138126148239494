import api from "../baseurl";

const SendData = async (companyUuid, token, employees, file) => {
    //console.log(data)
    const status = await api.post("rhs/upload-census", {json: {
        file,
        employees,
        companyUuid,
    },
    headers: {
        rhsession: token,
    }
    }).json();
    /* const call = api.extend({
        hooks:{
            beforeRequest:[
                request=>{
                    request.headers.set('rhsession' , token)
                }
            ]
        }
    })
    const status = await call.post("rhs/upload-census", {json: {
        file,
        employees,
        companyUuid,
    }}).json() */
    //console.log(status)
    return status ;
    
  };
  
  export default SendData ; 