import {
    isNullOrUndefined
  } from 'util';
 // import React from 'react';
  //import Avatar from '@material-ui/core/Avatar';
  //import imageCompression from 'browser-image-compression';
  
  const libAvatar = {
  
    getAvatarChars(name, lastName) {
      if (!isNullOrUndefined(name) && !isNullOrUndefined(lastName)) {
        var initials = name.charAt(0) + lastName.charAt(0)
        return initials
      } else {
        return 'NA'
      }
    },
  
    async optimizeImage(imagePNGorJPEG){
      /* const image = sharp(imagePNGorJPEG);
      image
        .metadata()
        .then(function (metadata) {
        return image
          //.resize(800,800)//Math.round(metadata.width / 2),(Math.round(metadata.height / 2)))
          .jpeg({
            quality: 85
            })
          .png({
            compressionLevel: 8
            })
          .toFile('ouput.jpeg');
          }) */
      //console.log('originalFile instanceof Blob', imagePNGorJPEG instanceof Blob) // true
      //console.log(`originalFile size ${imagePNGorJPEG.size / 1024 / 1024} MB`)
      const options = { 
        maxSizeMB: 1,          // (default: Number.POSITIVE_INFINITY)
        maxWidthOrHeight: 1920,   // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
        useWebWorker: false,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
        // maxIteration: number        // optional, max number of iteration to compress the image (default: 10)
          }
       
        try {
          if((imagePNGorJPEG.size / 1024 / 1024)>= 1){
          //const compressedFile = await imageCompression(imagePNGorJPEG, options);
          //console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          return (null)//compressedFile);
          }else{
            //console.log('Don\'t need compress')
            return imagePNGorJPEG
          }
  
        //console.log(reader.readAsBinaryString(compressedFile))
       // write your own logic
      }catch (error) {
        //console.log(error);
      }
    },
    


async getbase64(img){

  const optImage = await this.optimizeImage(img)

  let file = optImage;
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    return reader.result
  };


},

    async getBinaryString(image) {
        //const accept = {
        //binary : ["image/png", "image/jpeg"],
        //text   : ["text/plain", "text/css", "application/xml", "text/html"]
        // };
        if (image !== null) {
          const optImage = await this.optimizeImage(image)
          //console.log(optImage)
          // const reader = new FileReader()
          
          // reader.onload = function () {
          // console.log (reader.result)
          // }
          // reader.readAsArrayBuffer(optImage)
          return new Promise(function(resolve, reject) {
            var reader = new FileReader();
            reader.onload = function() { resolve(reader.result); };
            reader.onerror = reject;
            reader.readAsBinaryString(optImage) 
          });
          //if (accept.binary.indexOf(optImage.type) > -1) {
          // file is a binary, which we accept
          //return optImage
        } //else if (accept.text.indexOf(optImage.type) > -1) {
        // file is of type text, which we accept
        //return optImage.getAsText();
        // modify data with string methods
        //}
        //}
      },
  
      getImg(image) {
        if (!isNullOrUndefined(image)) {
          const img = "data:image/jpeg;base64," + btoa(image)
          return img
        }
        else {
          
        }
      },
  
      hexToBase64(str) {
        return btoa(String.fromCharCode.apply(null, str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ")));
      },
      async getBinaryStringFile(image) {
        if (image !== null) {
          
          return new Promise(function(resolve, reject) {
            var reader = new FileReader();
            reader.onload = function() { resolve(reader.result); };
            reader.onerror = reject;
            reader.readAsBinaryString(image)
          }); 
        } 
      },
      async getFile(image) {
        if (image !== null) {
          //console.log(image)
          return new Promise(function(resolve, reject) {
            var reader = new FileReader();
            reader.onload = function() { resolve(reader.result); };
            reader.onerror = reject;
            reader.readAsDataURL(image)
          });
          
        } 
      },
   }
   export default libAvatar
  